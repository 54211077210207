import React from "react";
import publikationen from '../data/publikationen.json';
import Page from "./Page";
import { List, SubNavigation } from "../components";

export default class PublikationenPage extends React.Component {
    elements = [
        { key: "monografien", value: "Monografien" },
        { key: "kommentare", value: "Beiträge in Kommentaren" },
        { key: "referate", value: "Referate und Vorträge" },
        { key: "sammelwerke", value: "Beiträge in Sammelwerken und Fachzeitschriften" }
    ]

    state = {
        selected: this.elements[0]
    }

    render() {
        return (
            <Page title="Publikationen" addition={this.createSubNavigation()}>
                <List elements={publikationen[this.state.selected.key]} />
            </Page>);
    }

    createSubNavigation() {
        return <SubNavigation title="Auswahl" elements={this.elements} selected={this.state.selected} onClick={selected => this.subNavItemClicked(selected)} />;
    }

    subNavItemClicked(selected) {
        this.setState({ selected });
        window.scrollTo(0, 0);
    }
}
