import React from "react";
import { Link } from "react-router-dom";
import { ClassNamer } from "../../util";
import Logo from "./niklaus-oberholzer_path.svg";
import "./Header.css";

export default class Header extends React.Component {
  state = {
    sticky: false
  };

  componentDidMount() {
    window.addEventListener("scroll", () => this.handleScroll());
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", () => this.handleScroll());
  }

  render() {
    const className = new ClassNamer("header");
    className.modifier("sticky", () => this.state.sticky);
    className.modifier("addition", () => this.props.addition);
    return (
      <div className={className.build()}>
        <Link to="/">
          <h1>
            <img
              className="header__logo"
              src={Logo}
              alt="Niklaus Oberholzer, Dr. iur. Rechtsanwalt"
            />
            <span className="detail">
              Niklaus Oberholzer, Dr. iur. Rechtsanwalt
            </span>
          </h1>
        </Link>
        <div className="header__page-title">
          <h2>
            {this.props.title}
            <span className="detail"> von Niklaus Oberholzer</span>
          </h2>
          {this.props.headerImage ? (
            <div className="header__image">
              <img src={this.props.headerImage} alt="Header Bild" />
            </div>
          ) : (
            ""
          )}
        </div>
        {this.props.addition ? (
          <div className="header__addition">{this.props.addition}</div>
        ) : (
          ""
        )}
      </div>
    );
  }

  handleScroll() {
    const small = window.innerWidth <= 667;
    const medium = window.innerWidth <= 1024;
    const paddingTop = small ? 24 : medium ? 48 : 96;
    const imageHeight = small ? 36 : medium ? 48 : 52;
    const titleHeight = this.props.addition && small ? 24 + 24 : 0;
    const headerHeight = paddingTop + imageHeight + titleHeight;
    const scrollPosition = window.pageYOffset;
    const sticky = scrollPosition > headerHeight;
    this.setState({ sticky });
  }
}
