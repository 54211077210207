import React from "react";
import { NavLink } from "react-router-dom";
import { ClassNamer } from "../../util";
import "./Navigation.css";

export default class Navigation extends React.Component {
  render() {
    const cn = new ClassNamer("navigation");
    cn.modifier("open", () => this.props.open);
    return (
      <div
        className={cn.build()}
        role="navigation"
        onClick={() => this.props.onClick()}
      >
        <ul>
          <li>
            <NavLink exact to="/">
              Startseite
            </NavLink>
          </li>
          <li>
            <NavLink to="aktuell">Aktuell</NavLink>
          </li>
          <li>
            <NavLink to="dienstleistungen">Dienstleistungen</NavLink>
          </li>
          <li>
            <NavLink to="lebenslauf">Lebenslauf</NavLink>
          </li>
          <li>
            <NavLink to="publikationen">Publikationen</NavLink>
          </li>
          <li>
            <NavLink to="expertenauftraege-und-gutachten">
              Expertenaufträge und Gutachten
            </NavLink>
          </li>
          <li>
            <NavLink to="wissenschaftliche-taetigkeiten">
              Wissenschaftliche Tätigkeiten
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}
