import React from "react";
import { Link } from "react-router-dom";
import Logo from "./niklaus-oberholzer_path.svg";
import Niklosch from "./Niklaus-Oberholzer_Startseite_crop.jpg";
import { Footer } from "../components";
import "./LandingPage.css";

export default class LandingPage extends React.Component {
  render() {
    return (
      <div className="landing-page">
        <div className="landing-page__content">
          <h1 className="landing-page__logo">
            <img src={Logo} alt="Niklaus Oberholzer, Dr. iur. Rechtsanwalt" />
            <span className="detail">
              Niklaus Oberholzer, Dr. iur. Rechtsanwalt
            </span>
          </h1>
          <div className="landing-page__text">
            <h2>
              Juristische Beratung, Gutachten, Projektbegleitung, anwaltliche
              Koordination
            </h2>
            <div className="landing-page__aktuell">
              <p>
                <Link to="/aktuell">
                  Aktuell: Grundzüge des Strafprozessrechtes in der
                  4.&nbsp;Auflage
                </Link>
              </p>
            </div>
          </div>
          <img
            src={Niklosch}
            alt="Bild von Niklaus Oberholzer"
            className="landing-page__image"
          />
        </div>
        <Footer />
      </div>
    );
  }
}
