import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  DienstleistungenPage,
  ExpertenauftraegePage,
  NewsPage,
  LandingPage,
  LebenslaufPage,
  PublikationenPage,
  WissenschaftlichPage,
  KontaktPage,
} from "./pages";
import NavigationButton from "./components/Navigation/NavigationButton";
import { Navigation } from "./components";
import { ClassNamer, ScrollToTop } from "./util";
import "./App.css";

export default class App extends React.Component {
  state = {
    navigationOpen: false,
  };

  render() {
    const switchClassName = new ClassNamer("main");
    switchClassName.modifier(
      "navigation-open",
      () => this.state.navigationOpen
    );
    return (
      <BrowserRouter>
        <Navigation
          onClick={() => this.toggleNavigation()}
          open={this.state.navigationOpen}
        />
        <NavigationButton onClick={() => this.toggleNavigation()} />
        <div className={switchClassName.build()}>
          <ScrollToTop />
          <Switch>
            <Route path="/dienstleistungen" component={DienstleistungenPage} />
            <Route path="/aktuell" component={NewsPage} />
            <Route path="/lebenslauf" component={LebenslaufPage} />
            <Route path="/publikationen" component={PublikationenPage} />
            <Route
              path="/expertenauftraege-und-gutachten"
              component={ExpertenauftraegePage}
            />
            <Route
              path="/wissenschaftliche-taetigkeiten"
              component={WissenschaftlichPage}
            />
            <Route path="/kontakt" component={KontaktPage} />
            <Route component={LandingPage} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }

  toggleNavigation() {
    this.setState({
      navigationOpen: !this.state.navigationOpen,
    });
  }
}
