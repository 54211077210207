import React from "react";
import { Caption } from "..";
import "./Navigation.css";

export default class SubNavigation extends React.Component {
    render() {
        return (
            <div className="subnavigation">
                <Caption green>{this.props.title}</Caption>
                <ul>
                    {this.props.elements.map(el => {
                        return (
                            <li key={el.key}>
                                <button className={this.props.selected.key === el.key ? "active" : ""} area-label="Typ der Publikation" onClick={() => this.selectElement(el)}>
                                    {el.value}
                                </button>
                            </li>);
                    })}
                </ul>
            </div>);
    }

    selectElement(element) {
        this.props.onClick(element);
    }
}
