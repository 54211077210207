import React from "react";
import "./Caption.css";
import { ClassNamer } from "../../util";

export default class Footer extends React.Component {
  render() {
    const className = new ClassNamer("caption");
    className.modifier("green", () => this.props.green);
    className.modifier(this.props.className);
    return <span className={className.build()}>{this.props.children}</span>;
  }
}
