import React from "react";
import { Header } from "../components";
import './Page.css';
import { ClassNamer } from "../util";

export default class Page extends React.Component {
  render() {
    const mainClassName = new ClassNamer("page");
    mainClassName.modifier("with-addition", () => this.props.addition);
    const contentClassname = new ClassNamer("page--content");
    contentClassname.modifier(this.props.className);
    return (
      <div className={mainClassName.build()}>
        <Header title={this.props.title} addition={this.props.addition} headerImage={this.props.headerImage} />
        <div className={contentClassname.build()}>{this.props.children}</div>
        {/* <Footer /> */}
      </div >
    );
  }
}
