export default class ClassNamer {
  modifiers = [];

  constructor(element) {
    this.element = element;
  }

  modifier(modifier, condition = undefined) {
    this.modifiers.push([modifier, condition]);
  }

  build() {
    const classNames = [this.element];
    this.modifiers.forEach(modifier => {
      const suffix = modifier[0];
      const condition = modifier[1];
      if (condition && !condition()) {
        return;
      } else if (!condition) {
        classNames.push(suffix);
      } else {
        classNames.push(`${this.element}--${suffix}`);
      }
    });
    return classNames.join(" ");
  }
}
