import React from "react";
import Page from "./Page";
import GrundzuegeDesStrafprozessrechts4 from "./grundzuege-des-strafprozessrechts-4.jpg";

export default class NewsPage extends React.Component {
  render() {
    return (
      <Page title="Aktuell" headerImage={GrundzuegeDesStrafprozessrechts4}>
        <div role="article">
          <h3>Grundzüge des Strafprozessrechts: Neuerscheinung März 2020</h3>
          <div>
            <h4>Klappentext</h4>
            <p>
              Das vorliegende Werk von Niklaus Oberholzer orientiert sich an
              einer systematischen Darstellung des Schweizerischen
              Strafprozessrechts. Es beschränkt sich nicht auf eine isolierte
              Kommentierung der einzelnen Artikel, sondern versucht, die
              prozessualen Bestimmungen in ihrem Gesamtzusammenhang zu erfassen.
              Es erläutert die dogmatischen Grundprinzipien des
              Strafprozessrechts und stellt die bundesgerichtliche
              Rechtsprechung weitgehend vollständig dar. Unstimmigkeiten werden
              aufgezeigt und Entwicklungen der Rechtsprechung werden kritisch
              hinterfragt. Die «Grundzüge des Strafprozessrechts» sind
              unentbehrlich für all jene, die sich mit Fragen der
              Rechtsanwendung im Strafverfahren auseinandersetzen. Dies gilt
              gleichermassen für Rechtsanwältinnen und Rechtsanwälte wie auch
              für Mitglieder der Strafverfolgungsbehörden und der Gerichte.
              Studierende begleitet es auf ihrem Weg zu einem besseren
              Verständnis der komplexen Abläufe des Strafverfahrens.
            </p>
            <p>
              Erhältlich beim{" "}
              <a
                href="https://www.staempfliverlag.com/detail/ISBN-9783727235146/Oberholzer-Niklaus/Grundz%FCge-des-Strafprozessrechts"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stämpfli Verlag
              </a>.
            </p>
            <img
              src={GrundzuegeDesStrafprozessrechts4}
              className="page__article__image"
              alt="Grundzüge des Strafprozessrechts von Niklaus Oberholzer"
            />
          </div>
        </div>
      </Page>
    );
  }
}
