import React from "react";
import { ReactComponent as LinkIcon } from "./external-link.svg";
import "./List.css";

export default class List extends React.Component {
  render() {
    return (
      <ul className="list">
        {this.props.elements.map(e => this.renderElement(e))}
      </ul>
    );
  }

  renderElement(element) {
    return (
      <li className="list__item" key={element.display}>
        {this.renderYear(element)}
        <div className="list__item__text">
          {element.urls ? (
            <a
              href={element.urls[0].url}
              target="_blank"
              rel="noopener noreferrer"
              dangerouslySetInnerHTML={{ __html: element.display }}
            />
          ) : (
            <span dangerouslySetInnerHTML={{ __html: element.display }}></span>
          )}
          {element.urls
            ? element.urls.map(u => (
                <a
                  href={u.url}
                  alt="Link zum Artikel"
                  target="__blank"
                  rel="noopener noreferrer"
                  className="list__item__text__link"
                >
                  <LinkIcon />
                  {u.date ? `(${this.renderDate(u.date)})` : null}
                </a>
              ))
            : null}
        </div>
      </li>
    );
  }

  renderYear(element) {
    const start = element.start
      ? new Date(element.start).getFullYear()
      : undefined;
    const end = element.end ? new Date(element.end).getFullYear() : undefined;
    let fromTo = `${start}–<wbr/>${end}`;
    if (!end) {
      fromTo = `Seit ${start}`;
    } else if (start === end) {
      fromTo = start;
    }
    if (this.props.showYear) {
      return (
        <div
          className="list__item__year"
          dangerouslySetInnerHTML={{ __html: fromTo }}
        />
      );
    }
  }

  renderDate(date) {
    date = new Date(date);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }
}
