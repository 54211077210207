import React from "react";
import Page from "./Page";
import CaptZollingerLogo from "./captzollinger.jpg";
import { Caption } from "../components";
import "./KontaktPage.css";

export default class KontaktPage extends React.Component {
  render() {
    return (
      <Page title="Kontakt">
        <div role="article" class="flex">
          <div>
            <h4 className="kontakt-h4">Niklaus Oberholzer</h4>
            <div className="footer--item">
              <Caption>Adresse</Caption>
              <p>
                Dr. Niklaus Oberholzer
                <br />
                Kesselhaldenstrasse 55
                <br />
                9016 St. Gallen
              </p>
            </div>
            <div className="footer--item">
              <Caption>Telefon</Caption>
              <p>
                <a
                  href="callto:+41712800522"
                  title="Niklaus Oberholzer anrufen"
                >
                  +41 71 280 05 22
                </a>
              </p>
            </div>
            <div className="footer--item">
              <Caption>E-Mail</Caption>
              <p>
                <a
                  href="mailto:mail@niklausoberholzer.ch"
                  title="E-Mail an Niklaus Oberholzer schreiben"
                >
                  mail@niklausoberholzer.ch
                </a>
              </p>
            </div>
          </div>
          <div>
            <h4 className="kontakt-h4">
              <img src={CaptZollingerLogo} alt="CaptZollinger Logo" />
            </h4>
            <div className="footer--item">
              <Caption>Adresse</Caption>
              <p>
                Dr. Niklaus Oberholzer
                <br />
                CaptZollinger Rechtsanwälte
                <br />
                Poststrasse 9
                <br />
                8620 Wetzikon
              </p>
            </div>
            <div className="footer--item">
              <Caption>Telefon</Caption>
              <p>
                <a
                  href="callto:+41449324233"
                  title="Niklaus Oberholzer anrufen"
                >
                  +41 44 932 42 33
                </a>
              </p>
            </div>
            <div className="footer--item">
              <Caption>E-Mail</Caption>
              <p>
                <a
                  href="mailto:n.oberholzer@captzollinger.ch"
                  title="E-Mail an Niklaus Oberholzer schreiben"
                >
                  n.oberholzer@captzollinger.ch
                </a>
              </p>
            </div>
          </div>
        </div>
      </Page>
    );
  }
}
