import React from "react";
import { ReactComponent as MenuSvg } from "./menu.svg";
import "./NavigationButton.css";

export default class NavigationButton extends React.Component {
  render() {
    return (
      <button
        className="button--navigation"
        aria-label="Navigation öffnen oder schliessen"
        onClick={() => this.props.onClick()}
      >
        <MenuSvg />
      </button>
    );
  }
}
