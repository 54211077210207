import React from "react";
import Page from "./Page";

export default class DienstleistungenPage extends React.Component {
  render() {
    return (
      <Page title="Dienstleistungen">
        <div role="article">
          <h3>
            Begleitung von Projekten auf dem Gebiet von Strafrecht,
            Strafprozessrecht und Strafbehördenorganisation
          </h3>
          <p>
            Als Gesetzesredaktor, Mitglied verschiedener Expertenkommissionen
            und beigezogener Gutachter für diverse Untersuchungen verfüge ich
            über grosse Erfahrungen bei der Initiierung, Ausarbeitung und
            Umsetzung komplexer Projekte auf den Gebieten des Strafrechts und
            des Strafprozessrechts sowie der Behördenorganisation.
          </p>
          <h3>
            Begleitung von komplexen Untersuchungen im Bereich von Polizei,
            Nachrichtendienst, Staatsanwaltschaft, Gerichten und
            Strafvollzugsbehörden
          </h3>
          <p>
            Die in unterschiedlichen Berufsfeldern gewonnenen Erfahrungen konnte
            ich in verschiedene Vorhaben gestaltend einbringen. Im Vordergrund
            stand die fachliche Begleitung parlamentarischer Untersuchungen in
            den Bereichen des Nachrichtendiensts, der Bundespolizei (fedpol) und
            der Bundesanwaltschaft. Als besonders herausfordernd erwies sich,
            dass die Fragestellungen unterschiedlicher Natur waren und
            dementsprechend auch eine individuelle Herangehensweise erforderten.
          </p>
          <h3>
            Rechtsgutachten zu strafrechtlichen und strafprozessualen Fragen
          </h3>
          <p>
            Im Verlauf meiner bisherigen beruflichen Tätigkeit wurde ich mit
            zahlreichen strafrechtlichen und strafprozessualen Fragestellungen
            in den unterschiedlichsten Konstellationen und aus unterschiedlicher
            Optik konfrontiert. Die Erfahrungen sind in meine universitäre
            Lehrtätigkeit, insbesondere in das in vierter Auflage erschienene
            Lehrbuch zum Strafprozessrecht, eingeflossen und haben ihren
            Niederschlag in zahlreichen Beiträgen in Fachzeitschriften und
            Referaten gefunden.
          </p>
          <h3>
            Strategische Koordination der Interessenvertretung in komplexen
            Strafverfahren und Beratung von Parteivertretern und
            Parteivertreterinnen in strafrechtlichen und strafprozessualen
            Fragestellungen
          </h3>
          <p>
            Strafrecht und Strafprozessrecht sind hochkomplexe Materien. Die
            entscheidenden Weichen werden nicht erst vor Gericht, sondern
            bereits im Vorverfahren vor der Polizei und der Staatsanwaltschaft
            gestellt.
          </p>
          <p>
            Während 40 Jahren war ich in unterschiedlichen Funktionen im
            Strafrecht und Strafprozessrecht tätig. Als Gerichtsschreiber eines
            erstinstanzlichen Gerichts, als kantonaler Untersuchungsrichter, als
            selbständiger Rechtsanwalt mit Schwerpunkt Strafverteidigung, als
            langjähriger Lehrbeauftragter, als Mitglied eines kantonalen
            Obergerichts (Präsident Anklagekammer und Anwaltskammer) als
            Bundesrichter und als Präsident der Aufsichtsbehörde über die
            Bundesanwaltschaft habe ich die unterschiedlichen Sichtweisen und
            Aufgaben der beruflichen Akteure des Strafverfahrens kennengelernt.
            Dies erlaubt es mir, die strategischen und taktischen Fragen der
            forensischen Tätigkeit im Strafverfahren einzuschätzen und
            dementsprechend Behörden, Parteivertreter und Parteivertreterinnen
            in komplexen Strafverfahren zu begleiten. Eine direkte Übernahme von
            Vertretungsmandaten ist nicht vorgesehen.
          </p>
        </div>
      </Page>
    );
  }
}
