import React from "react";
import expertenauftraege from "../data/expertenauftraege-gutachten.json";
import { List, Caption } from "../components";
import Page from "./Page";
import "./ExpertenauftraegePage.css";

export default class ExpertenauftraegePage extends React.Component {
  render() {
    return (
      <Page title="Expertenaufträge, Untersuchungen und Gutachten">
        <p>
          Aufgeführt sind nur Aufträge des Bundes, soweit die entsprechenden
          Berichte und Gutachten amtlich publiziert wurden oder in amtlichen
          Berichten darauf hingewiesen wurde.
        </p>
        <Caption className="expertenauftraege-page__caption">Auswahl</Caption>
        <List elements={expertenauftraege} />
      </Page>
    );
  }
}
