import React from "react";
import wissenschaftlich from "../data/wissenschaftliche-taetigkeiten.json";
import { List } from "../components";
import Page from "./Page";

export default class WissenschaftlichPage extends React.Component {
  render() {
    return (
      <Page title="Wissenschaftliche Tätigkeiten">
        <List elements={wissenschaftlich} showYear />
      </Page>
    );
  }
}
