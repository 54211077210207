import React from "react";
import "./Footer.css";
import { Caption } from "..";
import { Link } from "react-router-dom";

export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="footer--item">
          <Link className="button" to="/kontakt">
            Kontakt
          </Link>
        </div>
      </div>
    );
  }
}
