import React from "react";
import lebenslauf from "../data/lebenslauf.json";
import Page from "./Page";
import { List } from "../components";
import LebenslaufImage from "./niklaus-oberholzer_lebenslauf.jpg";

export default class LebenslaufPage extends React.Component {
  render() {
    return (
      <Page
        title="Lebenslauf"
        className="page__lebenslauf"
        headerImage={LebenslaufImage}
      >
        <img
          src={LebenslaufImage}
          className="page__article__image"
          alt="Niklaus Oberholzer Portrait"
        />
        <div role="article">
          <h3>
            Hauptberufliche Tätigkeiten
            <span className="detail">von Niklaus Oberholzer</span>
          </h3>
          <List elements={lebenslauf.hauptberuflich} showYear />
        </div>
        <div role="article">
          <h3>
            Nebenberufliche Tätigkeiten
            <span className="detail">von Niklaus Oberholzer</span>
          </h3>
          <List elements={lebenslauf.nebenberuflich} showYear />
        </div>
        <div role="article">
          <h3>
            Ausbildung<span className="detail">von Niklaus Oberholzer</span>
          </h3>
          <List elements={lebenslauf.ausbildung} showYear />
        </div>
      </Page>
    );
  }
}
